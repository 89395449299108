import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <header className="mx-auto container px-6 py-4">
    <div className="flex items-center h-10 flex-col md:flex-row">
      <div className="flex-grow">
        <Link
          className="flex items-center text-black font-bold text-2xl mb-2 md:mb-0"
          to="/"
        >
          Ricardo Macario
        </Link>
      </div>
      <div className="font-semibold text-gray-600">
        <Link className="pr-4 uppercase" to="/start-here/">
          Start here
        </Link>
        {/* <Link className="pr-4 uppercase" to="/blog">
          Blog
        </Link> */}
        <Link className="uppercase" to="/projects">
          Projects
        </Link>
      </div>
    </div>
  </header>
)

export default Header
